.card-reservation-type {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  aspect-ratio: 5 / 4;
  border: 2px solid var(--color-dark-gray);
  cursor: pointer;
}

.card-reservation-type img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.card-reservation-type .card-title {
  background-color: rgba(26, 26, 26, 0.5);
  color: #fff;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  font-weight: 700;
  font-size: 1.2rem;
  width: 90%;
  text-align: center;
  border-radius: 20px;
  margin: 0;
}
