.iconContainer {
  width: 58px;
}

.conditions {
  border: 1px solid #121316;
}

.conditionsIcons {
  margin-right: 0.5rem;
}

.buttonContinue {
  display: flex;
  justify-content: center;

  height: 4rem;
  font-size: 1.1rem;
  bottom: 3rem;
}

.btn {
  background-color: var(--color-primary);
  color: #000 !important;
}

.btn:disabled {
  background-color: var(--color-deep-gray) !important;
  color: #000 !important;
}

.consumption {
  color: #fff;
  width: 100%;
  background-color: var(--color-dark-gray);
  padding: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: .9rem;
}

.reservation .paymentSection {
  border-bottom: 1px dashed #555;
  padding: 1.5rem 0;
  position: relative;
}

.reservation .paymentSection:last-child {
  border-bottom: none;
}

.reservation .paymentAmount {
  color: var(--color-green-yellow);
  font-weight: 700;
}