@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn1.wellet.mx/fonts/Inter-Light.ttf");
  font-display: block;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn1.wellet.mx/fonts/Inter-Regular.ttf");
  font-display: block;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn1.wellet.mx/fonts/Inter-Medium.ttf");
  font-display: block;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn1.wellet.mx/fonts/Inter-SemiBold.ttf");
  font-display: block;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn1.wellet.mx/fonts/Inter-Bold.ttf");
  font-display: block;
}
