.cardContainer {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.card {
  background-color: #131313;
  border-radius: 10px;
  height: 30px;
  padding: 0 0.25rem;
}

.card img {
  height: 100%;
  object-fit: contain;
}
