.fade {
    opacity: 0;
  }
  
  .fade.show {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
  .fade-up-down {
    transform: translateY(100%);
    opacity: 0;
  }
  
  .fade-up-horizontal {
    transform: translateX(100%);
    opacity: 0;
  }
  
  .fade-up-down.show,
  .fade-up-horizontal.show {
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.1s ease-out, opacity 0.3s ease-out;
  }
  
  .fade-up-down.modal-fullscreen,
  .fade-up-horizontal.modal-fullscreen {
    transition: transform 0.3s ease-out, opacity 0.15s linear;
  }
  
  /* Animacion Fade left-right - De izquierda hacia derecha*/
  .slide-left-right {
    transform: translateX(-100%);
    opacity: 0;
  }
  
  .slide-left-right.show {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.3s ease-out, opacity 0.2s ease-out;
  }
  
  .slide-left-right.modal-fullscreen {
    transition: transform 0.3s ease-out, opacity 0.15s linear;
  }
  
  /* Animacion Fade left-right - De derecha  hacia izquierda*/
  .slide-right-left {
    transform: translateX(100%);
    opacity: 0;
  }
  
  .slide-right-left.show {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.3s ease-out, opacity 0.2s ease-out;
  }
  
  .slide-right-left.modal-fullscreen {
    transition: transform 0.3s ease-out, opacity 0.15s linear;
  }