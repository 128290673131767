.money {
    display: inline-block;
    padding-right: 15px;
    position: relative;
    line-height: 1;
}

.moneyCents {
    font-size: 0.625rem;
    position: absolute;
    right: 0px;
    top: 0px;
  }