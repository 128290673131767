.content-app {
  display: flex;
  flex-direction: column;

  background-color: transparent;
  height: 100%;
  min-height: 100%;
}

.content-app .bottom-fixed {
  padding-bottom: calc(110px + env(safe-area-inset-bottom));
}
