.header .header-title {
  font-size: 1.1rem;
}

.header .header-title.center {
  margin-left: -55px;
  text-align: center;
}

.header.app {
  top: 0;
  padding-top: calc(env(safe-area-inset-top) + 5px);
  z-index: 20;
  font-size: 17px;
}

.header .back {
  text-align: center;
  z-index: 1;
}

.header .back-button {
  width: 55px;
  height: 55px;
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: 0;
}

.logo {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid var(--color-dark-gray);
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-content {
  flex-grow: 1;
}
