

.reservation .show-logo img {
  border-radius: 50%;
  width: 5rem;
}

.reservation .show-name {
  font-size: 1.25rem;
  font-weight: 700;
  color:#FFFFFF;
  size: 24px
  ;
}
.sentences-capitalize:first-letter {
  text-transform: capitalize;
}
.reservation .consumption {
  color: #fff;
  width: 100%;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #131313;
  font-size: .9rem;
}

.reservation .balance-box {
  border-radius: 10px;
  border: 1px solid #7e7e7e;
  height: 72px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reservation .payment-amount {
  font-weight: 700;
  color: var(--color-green-yellow);
}

.terms-and-conditions p {
  padding-top: 1.25rem;
}

.terms-and-conditions ul {
  color: var(--color-lighter-gray);
}

.terms-and-conditions ul li {
  margin: 1rem 0;
}
