.payment-confirm-container {
  display: flex;
  flex-direction: column;
  min-height: 98vh;
}

.success-content {
  margin-top: 10%;
  flex: 1;
  padding: 20px;
}

.pointer {
  cursor: pointer;
}
.code-reserva {
  margin-top: 30px;
}

.payment-summary {
  background-color: #1a1a1a;
  border-radius: 20px;
  padding: 0 1.5rem;
}

.payment-summary .payment-section,
.reservation .payment-section {
  border-bottom: 1px dashed #555;
  padding: 1.5rem 0;
  position: relative;
}

.payment-summary .payment-section:last-child {
  border: none;
}

.support .support-title {
  letter-spacing: 2.5px;
  font-weight: 300;
}

.support p {
  margin-bottom: 0;
  color: var(--color-lighter-gray);
}

.payment-summary .show-name {
  font-size: 1.25rem;
  font-weight: 700;
}

.payment-summary .show-logo img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
}

.payment-summary .consumption {
  background-color: var(--color-dark-gray);
  border-radius: 10px;
  font-size: 0.9rem;
  padding: 0.6rem;
  text-align: center;
  width: 100%;
}
