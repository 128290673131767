/* Modal.css */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0); /* Fondo oscuro semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Asegura que esté por encima de todo el contenido */
}

.modal-backdrop {
  z-index: 0;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  color: #fff;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  padding-left: .75rem;
  padding-right: .75rem;
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
}

.modal-content .close {
  color: #fff;
  text-shadow: none;
  opacity: 0.65;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.modal-content .close-chevron {
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 100;
  width: 30px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  color: #fff;
  transform: rotate(180deg);
  border: none;
  background-color: transparent;
}

.modal-fullscreen {
  padding: 0 !important;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.modal-fullscreen .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100vh;
  margin: 0;
  /* background-color: var(--color-darker-gray); */
}

.modal-fullscreen .background-app {
  background: #0f0f0f;
  color: var(--color-font-primary);
}

.modal-fullscreen .modal-content {
  height: 100vh;
  border: 0;
  border-radius: 0;
  overflow-y: auto;
}

.modal-first {
  flex: 1 1 100%;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.modal-second {
  flex: 1 1 0;
}

.modal-button-container {
  padding-bottom: env(safe-area-inset-bottom);
}

.modal-fullscreen .font-small {
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}

.modal-fullscreen .font-small .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #fff;
  opacity: 1;
  text-shadow: none;
  font-weight: 300;
}

.background-modal {
  background-color: #0f0f0f;
}

.slide-left-right {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-left-right.show {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.3s ease-out, opacity 0.2s ease-out;
}

.slide-left-right.modal-fullscreen {
  transition: transform 0.3s ease-out, opacity 0.15s linear;
}

.modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

button.close {
  padding: 0;
  border: 0;
  appearance: none;
  background-color: transparent;
}
