footer {
  border-top: 1px solid #1a1a1a;
  position: relative;
}

footer .logo {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid var(--color-dark-gray);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

footer .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}