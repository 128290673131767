.restaurantCard {
  width: 100%;
  height: 112px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1a1a1a !important;
  cursor: pointer;
}

.containerImg {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border: 5px solid #353535;
}

.imgRestaurant {
  border-radius: 50%;
  width: 92%;
  height: 92%;
  border: 5px solid #353535;
}
.ChevronIconBig {
  margin-right: 20px;
}
