.treat-selector .treat-selector__control {
  background-color: transparent;
  border: none;
  min-height: 0;
  font-weight: bold;
  box-shadow: none;
  max-width: 68px;
}

.treat-selector .treat-selector__menu {
  z-index: 10;
  background-color: #141414;
  color: #fff;
  font-weight: bold;
}

.treat-selector .treat-selector__option--is-selected {
  background-color: #141414;
  color: #fff !important;
}

.treat-selector .treat-selector__option {
  background-color: #141414 !important;
}

.treat-selector .treat-selector__indicator-separator {
  display: none;
}

.treat-selector .treat-selector__indicator {
  margin: 0;
}

.treat-selector .treat-selector__value-container {
  padding: 0;
  box-shadow: none;
}

.reservation-form .treat-selector .treat-selector__single-value {
  color: #fff !important;
  margin-right: 0;
  margin-left: 0.5rem;
}

.input-customer-name .input-placeholder input {
  padding-left: 0;
}
