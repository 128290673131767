.rdp-month {
  width: 100%;
}

.rdp-nav_button[aria-disabled='true'] {
  display: none;
}

.rdp-nav_button.rdp-button {
  outline: none;
}

.rdp-button:focus,
.rdp-button:active {
  border: none;
  background-color: transparent;
}

.rdp-button:hover:not([aria-disabled='true']) {
  background-color: transparent;
}

.rdp-table {
  width: 100%;
  max-width: 100%;
}

.rdp-head_cell {
  text-transform: capitalize;
  color: var(--color-light-gray);
  font-weight: normal;
}

.rdp-caption {
  color: #000;
}

.rdp-caption_label {
  font-weight: 500;
}

.rdp-day {
  margin: 0 auto;
  color: #000;
}

.rdp-day_disabled {
  color: var(--color-light-gray) !important;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 1 !important;
}

.rdp-day_selected:not([aria-disabled='true']) {
  background-color: var(--color-primary) !important;
  color: #000 !important;
  border-radius: 0.5rem;
  font-weight: 700 !important;
}

.rdp-day_today {
  color: #fff;
}

.rdp-day:hover {
  background-color: transparent;
  font-weight: 400 !important;
  outline: none !important;
}

.rdp-day:hover .rdp-day_selected:not([aria-disabled='true']) {
  background-color: #503ec4 !important;
}

.rdp-day_today:not(.rdp-day_outside) {
  color: #000;
  font-weight: 500;
}
