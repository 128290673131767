.error-box {
  color: #000;
}

.error-box img {
  width: 25px;
}

.error-box .card {
  background-color: #fff !important;
  padding: 1rem;
  border-radius: 10px;
}

.error-box .error-title {
  font-weight: 700;
  color: #000;
}

.error-box .error-text {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.error-box .card-error {
  background-image: url("https://cdn1.wellet.fun/images/credit-card/card-error.png");
}
