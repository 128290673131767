html,
body {
  background-color: #000;
  min-height: 100%;
}

body {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  letter-spacing: -0.5px;
  line-height: normal;
  overflow-x: hidden;
  touch-action: pan-y;
}

button {
  outline: none !important;
  cursor: pointer;
}

/* -------------- VARIABLES --------------- */
:root {
  /* -------------- COLORS --------------- */
  --color-primary: #1d9bf0;
  --color-secondary: #05141e;
  --color-dark-blue: #0017e8;
  --color-dark-purple: #313166;

  --color-lighter-gray: #d9d9d9;
  --color-light-gray: #a3a3a3;
  --color-medium-gray: #898989;
  --color-deep-gray: #7e7e7e;
  --color-dark-gray: #131313;

  --color-light-green: #00ff7e;
  --color-green-yellow: #8fff00;
  --color-green: #029c4f;
  --color-red: #ff0046;
  --color-orange: #ff8421;
  --color-warning: #ffb648;
  --color-light-blue: #00a3ff;
  --color-dark-blue: #1f242f;
  --color-darker-blue: #0c111d;
  --color-commission: #556379;
  --color-alert: #a60707;
  --color-font-gray: #c0c0c0;
  --btn-primary-progress-color: #05141e;

  /* -------------- FONTS --------------- */
  --color-font-primary: #fff;
  --color-font-secondary: #7e7e7e;
  --color-font-lighter-gray: var(--color-lighter-gray);
}

/* -------------- END VARIABLES --------------- */

.text-secondary {
  color: var(--color-font-secondary) !important;
}

.text-primary {
  color: var(--color-primary);
}

.text-lighter-gray {
  color: var(--color-lighter-gray);
}

.font-extra-large {
  font-size: 1.8rem;
}

.font-large {
  font-size: 1.5rem;
}

.font-medium {
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.font-normal {
  font-size: 1.1rem;
}

.font-1rem {
  font-size: 1rem;
}

.font-small {
  font-size: 0.9rem;
}

.font-tiny {
  font-size: 0.85rem;
}

.font-smaller {
  font-size: 0.8rem;
}

.font-extra-small {
  font-size: 0.75rem;
}

.fw-semibold {
  font-weight: 600;
}

.light {
  font-weight: lighter;
}

.text-medium {
  font-size: 0.9375rem !important;
}

.border-none {
  border: none !important;
}

.loading-app {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #000;
  background-color: #000;
  z-index: 10;
}

.loaderDiv {
  margin-top: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  width: 100%;
  z-index: 10;
}

.loaderDiv div {
  border-width: 2px;
  margin: 0 auto;
}

.loaderDiv h2 {
  margin-top: 1rem;
  font-weight: normal;
  font-size: 1.2em;
  text-align: center;
}

.container-input-email {
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  padding: 0.5rem;
  position: relative;
}

.input-field-name {
  background-color: #000;
  left: 15px;
  padding: 0 0.3rem;
  position: absolute;
  top: -28px;
}

.field-name {
  position: absolute;
  top: -13px;
  left: 15px;
  background-color: #000;
  padding: 0 0.3rem;
}

.env-bottom {
  padding-bottom: calc(1rem + env(safe-area-inset-top));
}
