.container {
  color: #fff;
  padding-bottom: calc(55px + env(safe-area-inset-bottom));
}

.imageContainer {
  width: 126px;
  height: 111px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.seeMore {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  border: 2px solid #fff;
  line-height: 18px;
  text-align: center;
}
