.performance-selector {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.performance-selector .col-3 {
  padding-right: .5rem;
  padding-left: .5rem;
}

.performance-selector .col-first {
  padding-right: 10px !important;
  padding-left: 0 !important;
  padding-top: 10px !important;
}

.performance-selector .col-middle {
  padding-right: 10px !important;
  padding-left: 0 !important;
  padding-top: 10px !important;
}

.performance-selector .col-last {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 10px !important;
}

.performance-selector .show-time-btn {
  height: 40px;
  line-height: 54px;
  width: 100%;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d9d9d9;
  border: 1px solid var(--color-deep-gray);
  background-color: var(--color-dark-gray);
}

.performance-selector .selected-performance {
  border: 2px solid var(--color-primary) !important;
  font-weight: 700 !important;
}

.performance-selector .disabled-performance {
  opacity: 1;
  background-color: rgba(19, 19, 19, 0.5);
  border: 1px solid var(--color-dark-gray);
  /* text-decoration: line-through !important; */
  color: var(--color-light-gray);
}
