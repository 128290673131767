.btn {
  background-color: var(--color-primary);
  color: #000 !important;
}

.btn:disabled {
  background-color: var(--color-deep-gray) !important;
  color: #000 !important;
}

.containerImg {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    45deg,
    #fdc738,
    #fecb39,
    #fcaf45,
    #f7a536,
    #f59b38,
    #f18938,
    #ed6e41,
    #eb5d4c,
    #eb5850,
    #ea5055,
    #e94563,
    #e94168,
    #e83a85,
    #e8398d,
    #d24592,
    #a64b96
  );
}

.imgRestaurant {
  border-radius: 50%;
  width: 92%;
  height: 92%;
  border: 4px solid var(--color-dark-gray);
}

.title {
  color: var(--color-font-primary);
}

.customPax {
  background-color: var(--color-dark-gray);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  padding: 0.5rem;
  border-radius: 10px;
}

.customPaxError {
  width: 80%;
  margin-top: 0;
}

.cardSuccess {
  background-color: #1a1a1a;
}

.iconContainer {
  margin-top: 50%;
}
