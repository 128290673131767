.container-checkbox {
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    line-height: 16px;
  }
  
  .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #D9D9D9;
    border-radius: .25rem;
  }
  
  .container-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  .container-checkbox input:checked ~ .checkmark {
    background-color: var(--color-primary);
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  .container-checkbox .checkmark:after {
    left: 5px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }