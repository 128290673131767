.input-phone {
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  padding: 0.5rem 0;
  position: relative;
}

.input-phone .label {
    position: absolute;
    top: -13px;
    left: 15px;
    background-color: #000;
    padding: 0 0.3rem;
    font-size: 0.9rem;
    color: rgb(126, 126, 126);
    font-weight: bold;
}

.input-phone .PhoneInput {
  display: flex;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--bs-gutter-x)* -.5);
  margin-left: calc(var(--bs-gutter-x)* -.5);
}

.input-phone .PhoneInputCountry {
  flex: 0 0 auto;
  width: 90px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 0;
}

.input-phone .PhoneInputCountryIcon {
  margin-left: 1rem;
}

.input-phone .PhoneInputInput {
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 0;
  padding: 0.7rem 0;
  font-weight: bold;
  margin-right: 15px;
  outline: none;
}

.input-phone .PhoneInputCountrySelectArrow {
    display: none;
}

.input-phone .chevron {
    position: absolute;
    top: 50%;
    left: 51px;
    transform: translateY(-50%);
    height: 15px;
}
