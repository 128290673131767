.field-container {
  display: flex;
  position: relative;
}

.field-container.error {
  padding-bottom: 25px;
  padding-top: 5px;
}

.field-container.error .field {
  border: 1px solid red;
}

.field,
.field.mp-field {
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  padding: 0.5rem 0;
  position: relative;
  width: 100%;
}

.field-container.disabled .field {
  border: 1px solid transparent;
}

.field input {
  display: block;
  color: #fff;
  width: 100%;
  outline: 0;
  background-color: transparent;
  font-size: 1.2rem;
  border: none;
  position: relative;
  padding: 0 15px;
  height: 42px;
  font-weight: 700;
}

.field input:disabled {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  opacity: 1;
}

.field input[type="email"] {
  font-size: 1rem;
}

.field input::placeholder {
  color: var(--color-deep-gray) !important;
  font-weight: 700;
  opacity: 1;
}

.field input::-ms-input-placeholder {
  color: var(--color-deep-gray);
  font-weight: 700;
}

.field input:-webkit-autofill,
.field input:-webkit-autofill:hover,
.field input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 10rem #000000 inset;
}

.field .mp-input {
  padding: 0 15px;
  height: 42px;
  background-color: unset;
  color: #fff;
}

.field .mp-input input {
  color: #fff;
}

.field .icon-wellet {
  font-size: 24px;
  left: 1.25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.errorLabel {
  background-color: #f80005;
  border-radius: 10px;
  font-size: 0.8rem;
  margin-top: 8px;
  padding: 2px 5px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 999;
}

.errorLabel svg {
  margin-bottom: -2px;
}

.reveal {
  position: absolute;
  color: rgb(49, 0, 128);
  right: 40px;
  top: 34%;
  text-indent: 20px;
  transform: translate(50%, -50%);
}

/* .floatingPlaceholder {
  position: absolute;
  top: -10px;
  left: 15px;
  background-color: #000;
  padding: 0 0.3rem;
  font-size: 0.9rem;
  color: var(--color-deep-gray);
  font-weight: 700;
  max-width: 92%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.field .input-placeholder {
  position: relative;
}

.field .input-placeholder input {
  position: relative;
}

.field .input-icon {
  padding-left: 81px;
}

.field .input-icon input {
  padding: 0;
}

.input-placeholder {
  position: relative;
}

.field .treat-selector {
  margin-left: 0.5rem;
}

.field-container.disabled .treat-selector .treat-selector__indicator,
.field-container.disabled .input-phone .chevron {
  display: none;
}
